import React, { useEffect, useState } from "react";
import MainLayout from "../../layout/MainLayout";
import "react-slideshow-image/dist/styles.css";
import { initPage, getPortfolio, getImageUrl } from "../../api/Api";
import Loading from "../Loading";
import { Cell, Grid, Row } from "../FoundationGrid";
import MainPageHeader from "../mainpage/MainPageHeader";
import CallToAction from "../CallToAction";
import RevealFade from "react-reveal/Fade";
import { translate } from "../../helpers/I18n";
import { Link } from "gatsby";
import Icon from "../Icon";
import { isWindowAvailable } from "../../helpers/helpers";

const identifier = "portfolio";
const portfolioGroups = ["apps", "memberareas", "own"];

function PortfolioPage({ locale, entry }) {
  const [portfolio, setPortfolio] = useState(null);
  const [showLoading, setShowLoading] = useState(true);

  const [page, setPage] = useState({
    identifier: identifier,
    nav: "portfolio",
    locale: locale,
    parts: { mainPageSections: [], mainPageSpecialSections: [] },
    ctas: { home: null },
  });

  const init = () => {
    initPage(page, (page) => {
      setPage({ ...page });
    });
    getPortfolio(page.locale, (portfolio) => {
      setPortfolio(portfolio);
    });
  };

  const goToEntry = (entryIdentifier) => {
    const element = document.getElementById(entryIdentifier);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setTimeout(() => {
        if (isWindowAvailable) window.location.replace(`#${entryIdentifier}`);
      }, 1000);
    }
  };

  useEffect(init, []);

  useEffect(() => {
    window.setTimeout(() => {
      if (!entry) {
        setShowLoading(false);
        return;
      }
      const entryIdentifier = entry.replace("#", "");
      goToEntry(entryIdentifier);
      setShowLoading(false);
    }, 1000);
  }, [portfolio]);

  if (!page.data) return <Loading page={page} fullscreen />;

  return (
    <div className={`page main-page page-${page.identifier}`}>
      {showLoading && <Loading page={page} fullscreen />}
      <MainLayout page={page}>
        <Grid>
          <Row>
            <Cell sm={24}>
              <MainPageHeader page={page} noFullHeight />
            </Cell>
          </Row>
        </Grid>
        <Grid full>
          <Row>
            <Cell sm={24}>
              <Grid>
                <Row>
                  <Cell sm={24}>
                    <Portfolio
                      page={page}
                      portfolio={portfolio}
                      goToEntry={goToEntry}
                    />
                  </Cell>
                </Row>
              </Grid>
              <RevealFade bottom>
                <Grid style={{ marginTop: "60px" }}>
                  <Row>
                    <Cell sm={24}>
                      <CallToAction type="box" cta={page.ctas.home} />
                    </Cell>
                  </Row>
                </Grid>
              </RevealFade>
            </Cell>
          </Row>
        </Grid>
      </MainLayout>
    </div>
  );
}

const Portfolio = ({ page, portfolio, goToEntry }) => {
  return (
    <div className="__portfolio-component">
      <div className="__portfolio-navigation-sticky">
        <div className="__portfolio-navigation">
          {portfolioGroups.map((groupKey) => {
            return (
              <PortfolioNav
                key={groupKey}
                groupKey={groupKey}
                portfolio={portfolio}
                page={page}
                goToEntry={goToEntry}
              />
            );
          })}
        </div>
      </div>
      <div className="__portfolio-content">
        {portfolioGroups.map((groupKey) => {
          return (
            <PortfolioGroup
              key={groupKey}
              groupKey={groupKey}
              portfolio={portfolio}
              page={page}
            />
          );
        })}
      </div>
    </div>
  );
};

const PortfolioNav = ({ portfolio, groupKey, page, goToEntry }) => {
  return (
    <ul className="__portfolio-navigation-group">
      <li className="__portfolio-navigation-group-title">
        {translate(`portfolio.groups.${groupKey}.name`, page.locale)}
      </li>
      {portfolio.byGroup[groupKey].map((entry, index) => {
        return (
          <li key={index} className="__portfolio-navigation-group-entry">
            <Link
              onClick={(e) => {
                e.preventDefault();
                goToEntry(entry.Identifier);
              }}
              to={`#${entry.Identifier}`}
            >
              {entry.Title}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

const PortfolioGroup = ({ portfolio, groupKey, page }) => {
  return (
    <div className="__portfolio-group">
      <h2>{translate(`portfolio.groups.${groupKey}.name`, page.locale)}</h2>
      {portfolio.byGroup[groupKey].map((entry, index) => {
        return (
          <div
            key={index}
            className="__portfolio-group-entry"
            id={entry.Identifier}
          >
            <div className="__portfolio-group-entry-image">
              <img src={getImageUrl(entry.ImageBanner)} alt="" />
            </div>
            <div className="__portfolio-group-entry-content">
              <Grid>
                <Row margin="xy">
                  <Cell
                    sm={24}
                    md={12}
                    className="text-center medium-text-right"
                  >
                    <h3>{entry.Title}</h3>
                    <h4>{entry.SubTitle}</h4>
                    {entry.WebsiteUrl ? (
                      <div className="__portfolio-group-entry-websiteurl">
                        <a href={entry.WebsiteUrl} target="_blank">
                          {translate(`portfolio.gotowebsite`, page.locale)}{" "}
                          <Icon icon="share-square-o" right />
                        </a>
                      </div>
                    ) : null}
                  </Cell>
                  <Cell sm={24} md={12}>
                    <div
                      dangerouslySetInnerHTML={{ __html: entry.IntroText }}
                    />
                  </Cell>
                </Row>
              </Grid>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PortfolioPage;
