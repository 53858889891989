import { Link } from "gatsby";
import React, { useEffect } from "react";
import { Cell, Grid, Row } from "../FoundationGrid";
import Icon from "../Icon";
import { getImageUrl } from "../../api/Api";
import { isWindowAvailable } from "../../helpers/helpers";

function CallToAction({ cta, type, color }) {
  const balanceTwoBoxes = () => {
    const allTwoBoxes = document.querySelectorAll(
      ".__calltoaction-type-twoboxes .__calltoaction-section"
    );
    let highest = 0;

    for (let el of allTwoBoxes) {
      if (el && el.offsetHeight && el.offsetHeight > highest)
        highest = el.offsetHeight;
    }

    if (highest > 0) {
      for (let el of allTwoBoxes) {
        el.style.height = `${highest}px`;
      }
    }
  };

  useEffect(() => {
    balanceTwoBoxes();
    if (isWindowAvailable)
      window.addEventListener("resize", () => balanceTwoBoxes());
  }, []);

  return (
    <>
      {type === "box" && <CtaBox cta={cta} color={color} />}
      {type === "twoboxes" && <CtaTwoBoxes cta={cta} color={color} />}
      {type === "mvp" && <CtaMVP cta={cta} />}
      {!type && <CtaDefault cta={cta} color={color} />}
    </>
  );
}

const CtaDefault = ({ cta, color }) => {
  return (
    <div className="__calltoaction-type-default">
      <Link to={cta.ButtonUrl} className={`${color} __calltoaction-section`}>
        <Grid>
          <Row margin="xy">
            <Cell sm={24} md={18}>
              <h2 dangerouslySetInnerHTML={{ __html: cta.Title }} />
              <div
                className="__calltoaction-section-content"
                dangerouslySetInnerHTML={{ __html: cta.Content }}
              />
              {cta.Button1Label && (
                <Link to={cta.Button1Url} className="primary hollow button">
                  {cta.Button1Label}
                </Link>
              )}
            </Cell>
            <Cell sm={24} md={6}></Cell>
          </Row>
        </Grid>
      </Link>
    </div>
  );
};

const CtaBox = ({ cta, color }) => {
  return (
    <div className="__calltoaction-type-box">
      <Grid>
        <Row>
          <Cell sm={24}>
            <Link
              to={cta.Button1Url}
              className={`${color} __calltoaction-section`}
            >
              <Grid full>
                <Row margin="xy">
                  <Cell sm={24} md={18}>
                    <h2 dangerouslySetInnerHTML={{ __html: cta.Title }} />
                    <div
                      className="__calltoaction-section-content"
                      dangerouslySetInnerHTML={{ __html: cta.Content }}
                    />
                    <button className="primary hollow button">
                      {cta.Button1Label}
                    </button>
                    <div className="__calltoaction-section-photo">
                      <img
                        src="/static/images/photos/pt-free.png"
                        alt="Patrick Tschirpke"
                      />
                    </div>
                  </Cell>
                </Row>
              </Grid>
            </Link>
          </Cell>
        </Row>
      </Grid>
    </div>
  );
};

const CtaTwoBoxes = ({ cta, color }) => {
  return (
    <Grid>
      <Row>
        <Cell sm={24}>
          <Grid full>
            <Row margin="xy">
              <Cell sm={24} md={18}>
                <div className="__calltoaction-type-twoboxes">
                  <Link
                    to={cta.Button1Url}
                    className={`${color} __calltoaction-section`}
                  >
                    <h2 dangerouslySetInnerHTML={{ __html: cta.Title }} />
                    <div
                      className="__calltoaction-section-content"
                      dangerouslySetInnerHTML={{ __html: cta.Content }}
                    />
                    <button className="primary hollow button show-for-small-only">
                      {cta.Button1Label}
                    </button>
                  </Link>
                </div>
              </Cell>
              <Cell sm={24} md={6} className="hide-for-small-only">
                <div className="__calltoaction-type-twoboxes __calltoaction-type-twoboxes-icon">
                  <Link
                    to={cta.Button1Url}
                    className={`${color} __calltoaction-section`}
                  >
                    <div className="__calltoaction-section-content">
                      <Icon icon="envelope-o" />
                    </div>
                  </Link>
                </div>
              </Cell>
            </Row>
          </Grid>
        </Cell>
      </Row>
    </Grid>
  );
};

const CtaMVP = ({ cta }) => {
  return (
    <div className="__calltoaction-type-mvp">
      <Grid>
        <Row margin="xy">
          <Cell md={12}>
            <h2 dangerouslySetInnerHTML={{ __html: cta.Title }} />
            <div dangerouslySetInnerHTML={{ __html: cta.Content }} />
          </Cell>
          <Cell md={12} className="hide-for-small-only">
            <div className="__calltoaction-type-mvp-image center-vertically">
              {cta.Image && <img src={getImageUrl(cta.Image)} alt="" />}
            </div>
          </Cell>
        </Row>
      </Grid>
      <div className="__calltoaction-type-mvp-buttons">
        {cta.Button1Label && (
          <Link to={cta.Button1Url} className="primary button">
            <Icon icon="search" left /> {cta.Button1Label}
          </Link>
        )}
        {cta.Button2Label && (
          <Link to={cta.Button2Url} className="primary hollow button">
            <Icon icon="mobile" left /> {cta.Button2Label}
          </Link>
        )}
      </div>
    </div>
  );
};

export default CallToAction;
